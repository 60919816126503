import { isEnumValue } from '@gamepark/rules-api'

export enum Place {
  Svalbard = 1,
  Athens,
  Rome,
  Stonehenge,
  Thingvellir,
  Zagorsk,
  CaspianSea,
  Petra,
  Giza,
  Timgad,
  Newfoundland,
  Greenland,
  PutoranaPlateau,
  Novossibirsk,
  Babylone,
  Persepolis,
  Sanaa,
  Aksum,
  Sahara,
  Timbuktu,
  CanaryIslands,
  NorthwestPassage,
  Sakha,
  Harappa,
  TajMahal,
  Virunga,
  Kush,
  Douala,
  Elmina,
  NiagaraFalls,
  Banff,
  Kolyma,
  LakeBaikal,
  GreatWall,
  MountEverest,
  Bagan,
  Sigiriya,
  VictoriaFalls,
  PuertoRico,
  Louisiane,
  OldFaithful,
  CraterLake,
  MackenzieDelta,
  BeringStrait,
  AmurRiver,
  MountFuji,
  Xian,
  AngkorVat,
  Madagascar,
  Omatako,
  SalvadorDeBahia,
  Tikal,
  Teotihuacan,
  GrandCanyon,
  Denali,
  Sulawesi,
  Borobudur,
  AtlanticOcean,
  IguazuFalls,
  Marajo,
  SaltoAngel,
  GalapagosIslands,
  Papua,
  ArnhemLand,
  BungleBungleRange,
  IndienOcean,
  Atacama,
  Aripuana,
  AmazonRainforest,
  PacificOcean,
  GreatBarrierReef,
  Uluru,
  Perth,
  GrahamLand,
  TierraDelFuego,
  Altiplano,
  MachuPicchu,
  RapaNui,
  Tasmania,
  FiordlandNationalPark
}

export const places = Object.values(Place).filter<Place>(isEnumValue)

export const places2StepsFromStart = [
  Place.Svalbard,
  Place.Athens,
  Place.Rome,
  Place.Stonehenge,
  Place.Thingvellir,
  Place.Zagorsk,
  Place.CaspianSea,
  Place.Petra,
  Place.Giza,
  Place.Timgad,
  Place.Newfoundland,
  Place.Greenland
]
